import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { styles } from './styles'
import Button from '../button'
import arrow from '../../assets/images/arrow-down.svg'
import homeHero1 from '../../assets/images/home-hero-1.png'
import homeHero2 from '../../assets/images/home-hero-2.png'
import homeHero3 from '../../assets/images/home-hero-3.png'
import homeHero4 from '../../assets/images/home-hero-4.png'
import homeHero5 from '../../assets/images/home-hero-5.png'
import chirpyestLogo from '../../assets/images/chirpyest-logo.svg'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

import { Carousel as RCarousel } from 'react-responsive-carousel'
import { IOS_APP_LINK } from '../../constants'

interface CarouselItemProps {
  children?: React.ReactNode
  slideNumber?: string
  title: string
  text?: string
  isTestPage?: boolean
}

interface CarouselProps {
  children: React.ReactNode
}

const CarouselItem = ({
  children,
  slideNumber,
  title,
  text,
  isTestPage,
}: CarouselItemProps) => {
  const classes = styles()
  return (
    <div className={classes.carouselItem}>
      <div className={classes.wrapper}>
        {children}
        {
          <Box mt={2.5}>
            {slideNumber && (
              <Box mb={1.25}>
                <Typography variant="body1" className={classes.slideNumber}>
                  {slideNumber}
                </Typography>
              </Box>
            )}
            <Typography variant="body1" className={classes.slideTitle}>
              {title}
            </Typography>
          </Box>
        }
        {text && (
          <Box mt={1.25}>
            <Typography variant="body1" className={classes.slideText}>
              {text}
            </Typography>
          </Box>
        )}
        {!isTestPage && (
          <Box mt={1.25}>
            <Button
              onClick={() => window.open(IOS_APP_LINK)}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label="download the app"
            />
          </Box>
        )}
      </div>
    </div>
  )
}

const Carousel = ({ children }: CarouselProps) => {
  const classes = styles()

  return (
    <div className={classes.Container}>
      <RCarousel
        autoPlay={false}
        showArrows={true}
        showIndicators={false}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={false}
        swipeable={true}
        emulateTouch={true}
        axis="vertical"
        renderArrowPrev={(clickHandler: Function, hasPrev: boolean) => {
          return !hasPrev ? (
            <div className={classes.scrollLabel}>
              <Typography variant="body1">scroll to find out how</Typography>
            </div>
          ) : null
        }}
        renderArrowNext={(clickHandler: Function, hasNext: boolean) => {
          return hasNext ? (
            <div className={classes.carouselFooter}>
              <div
                className={classes.arrowContainer}
                onTouchStart={clickHandler}
              >
                <img
                  alt="Scroll to find out how"
                  src={arrow}
                  className={classes.arrow}
                />
              </div>
            </div>
          ) : null
        }}
      >
        {children}
      </RCarousel>
    </div>
  )
}

const HomeHero = ({ isTestPage }: any) => {
  const classes = styles()

  return (
    <Carousel>
      <div className={classes.carouselItem}>
        <div className={classes.wrapper}>
          <img
            src={homeHero5}
            className={classes.firstSlideImage}
            alt="download the chirpyest app"
          />
          <Typography variant="h2" className={classes.mainHeader}>
            shop. share. earn cash back.
          </Typography>
          <Box mt={2.5} mb={1.25}>
            <Typography variant="body1" className={classes.slideText}>
              download our extension. <br /> visit connected sites. <br />
              earn money when you shop. <br /> earn more when you share
              products.
            </Typography>
          </Box>
          {!isTestPage && (
            <Button
              onClick={() => window.open(IOS_APP_LINK)}
              customStyle={classes.button}
              customTextStyle={classes.buttonText}
              label="add to safari – its free!"
            />
          )}
        </div>
      </div>

      <CarouselItem
        slideNumber="#1"
        title="download the chirpyest app"
        isTestPage={isTestPage}
      >
        <img
          src={homeHero1}
          className={classes.slideImage}
          alt="download the chirpyest app"
        />
      </CarouselItem>

      <CarouselItem
        slideNumber="#2"
        title="enable the safari extension"
        isTestPage={isTestPage}
      >
        <video
          playsInline
          loop
          autoPlay
          muted
          preload="none"
          className={classes.video}
        >
          <source
            src="https://dl.dropboxusercontent.com/sh/kgzyvpvjy64dqxb/AABzwZ3r05QNwNn9zuJZogTSa/chirpyest_2_enable%20the%20safari%20extension_v1.mp4?dl=0"
            type="video/mp4"
          />
        </video>
      </CarouselItem>

      <CarouselItem
        title="shop at over 800+ online retailers"
        text="the chirpyest extension finds cash back for you."
        isTestPage={isTestPage}
      >
        <video
          playsInline
          loop
          autoPlay
          muted
          preload="none"
          className={classes.video}
        >
          <source
            src="https://dl.dropboxusercontent.com/sh/kgzyvpvjy64dqxb/AABOopwyW01731s_eMqUEtgQa/chirpyest_7_easily%20see%20which%20brands%20offer%20the%20best%20cash%20back_v3.mp4?dl=0"
            type="video/mp4"
          />
        </video>
      </CarouselItem>

      <CarouselItem
        title="activate cash back then shop + share!"
        text="make money two ways. shop & earn cash back. share product links &
        earn more cash back."
        isTestPage={isTestPage}
      >
        <div className={classes.outerCircle}>
          <div className={classes.innerContent}>
            <img
              src={chirpyestLogo}
              alt="chirpyest logo"
              className={classes.logo}
            />
            <div>
              <Typography variant="h3" className={classes.circleTitle}>
                get <b>5% cash back</b>
              </Typography>
              <Typography variant="body1" className={classes.circleText}>
                tap to activate
              </Typography>
            </div>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => {}}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </CarouselItem>

      <CarouselItem
        title="create & curate your own online store"
        text="create a shopping board of items from partner brands using the
            extension or app and earn cash back when people shop it"
        isTestPage={isTestPage}
      >
        <img
          src={homeHero2}
          className={classes.slideImage}
          alt="create & curate your own online store"
        />
      </CarouselItem>

      <CarouselItem
        title="share + earn"
        text="create a shareable link directly from the safari extension or from
            the app and earn cash back if someone shops from it"
        isTestPage={isTestPage}
      >
        <img
          src={homeHero3}
          className={classes.slideImage}
          alt="share + earn"
        />
      </CarouselItem>

      <CarouselItem
        title="get paid"
        text="Chirpyest automatically pays you through Paypal/Venmo twice a month
            once your cash back is closed"
        isTestPage={isTestPage}
      >
        <img src={homeHero4} className={classes.slideImage} alt="get paid" />
      </CarouselItem>

      <CarouselItem
        title="shop. share. earn cash back."
        text="what are you waiting for?"
      >
        <img
          src={homeHero5}
          className={classes.slideImage}
          alt="shop. share. earn cash back."
        />
      </CarouselItem>
    </Carousel>
  )
}

export default HomeHero
