import { makeStyles } from '@material-ui/core/styles'
import { COLORS, FONTS, BREAKPOINTS } from '../../constants'

export const styles = makeStyles(() => ({
  Container: {
    '& li': {
      height: 'calc(100vh - 200px)', // where 200px is the height of the header
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    height: 'calc(100vh - 200px)', // where 200px is the height of the header
  },
  carouselFooter: {
    textAlign: 'center',
    height: '40px',
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    paddingBottom: 0,
    bottom: '50px',
    width: '100%',
  },
  arrowContainer: {
    width: '100%',
  },
  scrollLabel: {
    textAlign: 'center',
    height: '40px',
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%, 0)',
    paddingBottom: 0,
    bottom: '70px',
  },
  arrow: {
    width: '14px',
    height: '8px',
    marginTop: '20px',
    marginBottom: '20px',
    zIndex: 10000000,
  },
  carouselItem: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  firstSlideImage: {
    maxWidth: '200px',
    maxHeight: '200px',
    display: 'none !important',
    [`@media (min-width: ${BREAKPOINTS.xmd}px) and (max-width: ${BREAKPOINTS.lg}px)`]: {
      display: 'block !important',
      margin: 'auto',
      maxWidth: '400px',
      maxHeight: '400px',
      marginBottom: '10px',
    },
  },
  mainHeader: {
    fontSize: '32px',
    fontWeight: 500,
    fontFamily: FONTS.Graphik.GraphikMedium,
    marginBottom: '20px',
    maxWidth: '250px',
    margin: 'auto',
    // [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
    //   maxWidth: '250px',
    //   margin: 'auto',
    // },
  },
  slideText: {
    fontSize: '16px',
    // padding: '0 15%',
    fontFamily: FONTS.Graphik.GraphikRegular,
    [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
      padding: '0 15%',
    },
  },
  slideTitle: {
    fontSize: '28px',
    lineHeight: '30.8px',
    fontFamily: FONTS.Graphik.GraphikMedium,
    [`@media (min-width: ${BREAKPOINTS.xmd}px)`]: {
      maxWidth: '255px',
      margin: 'auto',
    },
  },

  slideNumber: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '12px',
  },
  slideImage: {
    maxWidth: '200px',
    maxHeight: '200px',
    [`@media (min-width: ${BREAKPOINTS.xmd}px) and (max-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: '400px',
      maxHeight: '400px',
    },
  },
  button: {
    backgroundColor: '#BFD3D0',
    borderColor: '#BFD3D0',
    border: '1px solid #000000 !important',
    maxHeight: '40px',
    maxWidth: '200px',
  },
  buttonText: {
    color: '#000000 !important',
    fontSize: '13px',
  },
  wrapper: {
    paddingLeft: '30px',
    paddingRight: '30px',
    textAlign: 'center',
  },

  outerCircle: {
    width: '200px',
    height: '200px',
    borderRadius: '50%',
    backgroundColor: COLORS.whiteStain,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [`@media (min-width: ${BREAKPOINTS.xmd}px) and (max-width: ${BREAKPOINTS.lg}px)`]: {
      width: '400px',
      height: '400px',
    },
  },
  innerContent: {
    width: '100%',
    height: '89px',
    backgroundColor: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  circleTitle: {
    fontSize: '21px',
    fontFamily: FONTS.Graphik.GraphikRegular,
    marginBottom: '5px',
  },
  circleText: {
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '16px',
  },
  closeButton: {
    width: 8,
    height: 8,
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    color: '#000000',
  },
  logo: {
    maxWidth: '38px',
    maxHeight: '40.2px',
  },
  video: {
    textAlign: 'center',
    // maxWidth: '200px',
    maxHeight: '200px',
    width: '100%',
    [`@media (min-width: ${BREAKPOINTS.xmd}px) and (max-width: ${BREAKPOINTS.lg}px)`]: {
      maxWidth: '400px',
      maxHeight: '400px',
    },
  },
}))
